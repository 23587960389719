.h-100 {
  height: 100% !important;
}
.login {
  height: 100vh;
}
.btn-videostar {
  color: #fff;
  background-color: #000;

  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-videostar:hover {
  color: #fff;
  background-color: gray;
}
.btn-videostar.focus,
.btn-videostar:focus {
  color: #fff;
  background-color: gray;

  -webkit-box-shadow: 0 0 0 0.2rem gray;
  box-shadow: 0 0 0 0.2rem gray;
}
.btn-videostar.disabled,
.btn-videostar:disabled {
  color: #fff;
  background-color: gray;
}

.btn-videostar:not(:disabled):not(.disabled).active,
.btn-videostar:not(:disabled):not(.disabled):active,
.show > .btn-videostar.dropdown-toggle {
  color: #fff;
  background: gray;
  border-color: gray;
}
.btn-videostar:not(:disabled):not(.disabled).active:focus,
.btn-videostar:not(:disabled):not(.disabled):active:focus,
.show > .btn-videostar.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem gray;
  box-shadow: 0 0 0 0.2rem gray;
}
.alert {
  font-family: "proxima_nova_rgregular";
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  border: 0;
  padding: 12px 57px 10px;
  margin-bottom: 10px;
}
.alert-icon {
  position: absolute;
  left: 11px;
  top: 11px;
}
.alert h5 {
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}
.alert p {
  margin-bottom: 0;
}
.alert-right {
  padding-left: 0;
  margin-left: auto;
  position: absolute;
  top: 15px;
  right: 24px;
}
.alert-error {
  background: #feeaea;
  color: #fa3434;
}
.alert-info {
  background: #f1f8ff;
  color: #007bff;
}
.alert-success {
  background: #e5f7e7;
  color: #00b517;
}
.alert-warning {
  background: #fff3e7;
  color: #ff9017;
}
.alert.alert-sm {
  padding: 10px 36px;
}
.alert.alert-sm h5 {
  font-size: 13px;
}
.alert.alert-sm .alert-icon {
  top: 8px;
  left: 10px;
  width: 18px;
}
.alert.alert-sm .alert-right {
  top: 7px;
  right: 13px;
}
.alert-container {
  width: calc(100% - 40px);
  max-width: 497px;
  position: fixed;
  top: 50px;
  right: 20px;
  z-index: 99;
}
.alert.alert-white {
  background: #fff;
  border: 2px solid #f5f5f5;
  box-shadow: 0px 6px 20px 0px #0000001a;
  color: #000;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.7px;
  padding: 28px 50px 28px 60px;
  font-weight: 400;
}
.alert.alert-white p {
  margin-bottom: 0;
}
.alert-success.alert-white .alert-icon {
  color: #26ca97;
}
.alert-white .alert-icon {
  margin-right: 0;
  font-size: 20px;
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
}
.alert-white .alert-right {
  padding-left: 0;
  margin-left: auto;
  position: absolute;
  top: 50%;
  right: 25px;
  font-size: 25px;
  transform: translateY(-50%);
}
.alert-white .alert-close {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}
.alert.alert-white.alert-danger .alert-icon {
  color: #ba0303;
}

.btn-close:focus {
  box-shadow: none !important;
}

.load-msg .btn-loader {
  left: 0;
  margin-right: 17px;
}
.btn.btn-md {
  min-width: 120px !important;
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container {
  z-index: 999;
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}

.fa-exclamation-circle.alert-red {
  color: #ba0303 !important;
}

.btn.btn-clean:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text)
  .svg-icon
  svg
  g
  [fill],
.show .btn.btn-clean.btn-dropdown .svg-icon svg g [fill],
.show > .btn.btn-clean.dropdown-toggle .svg-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #c5a28b;
}
.datatable-cell {
  word-break: break-all;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 50px;
  background-color: transparent;
  padding: 5px;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
}
.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.upload-btn-wrapper .btn {
  color: #000;
  background-color: #e4e6ef;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  min-width: 150px;
}
.datepicker {
  width: 100% !important;
}
.text-white {
  color: #fff;
}
